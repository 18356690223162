import dayjs from "dayjs";
import { translate } from "@/utils/utils";
export default {
  data() {
    return {};
  },
  methods: {
    translate,
    /*
     * @param {Array} newsList 服务器返回的新闻列表
     * @param {Array} localNewsList 本地新闻列表
     * @param {Number} type 新闻类型
     * @returns {Array}
     */
    formatNews(newsList, localNewsList, type = 0) {
      const token = this.$route.query.token;
      const params = token ? `?token=${token}` : "";
      const lang = this.$store.state.language;
      const list = [];
      if (!newsList) {
        newsList = [];
      }
      newsList.forEach((item) => {
        const { main_img, title, content } = item[lang];
        // 过滤 type=0 时为全部新闻
        if (type !== 0 && item.type !== type) return;
        // 全部新闻时过滤赛事和福利
        if (type === 0 && (item.type === 5 || item.type === 6)) return;
        // 空数据过滤
        if (!title || !main_img || !content) return;
        let basePath = "news";
        if (item.type === 5) {
          basePath = "tournament";
        } else if (item.type === 6) {
          basePath = "special";
        }
        list.push({
          href: `/${basePath}/${item.nkey}${params}`,
          image: main_img,
          title: title,
          subtitle: dayjs(item.release_at * 1000).format("YYYY.MM.DD"),
          button: "MORE",
          topTag: this.$store.state.newsTagMap[item.type],
        });
      });
      // 排序
      return [...list, ...localNewsList].sort((a, b) => dayjs(b.subtitle, "YYYY.MM.DD") - dayjs(a.subtitle, "YYYY.MM.DD"));
    },
  },
};
