<template>
  <content-comp :backTitle="imgUrl" :listItems="tournaments" />
</template>
<script>
import newsList from "@/mixins/news/newsList";
import { tourContentList } from "../../configs/tour-content";
import ContentComp from "../../components/ContentComp.vue";

export default {
  mixins: [newsList],
  components: {
    ContentComp,
  },
  data() {
    return {};
  },
  computed: {
    imgUrl() {
      return `/static/img/tournament/tournamentBackground_${this.$store.state.language}.jpg`;
    },
    tournaments() {
      return this.formatNews(
        this.$store.state.allNewsList,
        tourContentList(this.$store.state.language),
        5
      );
    },
  },
};
</script>
